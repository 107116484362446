<template>
    <div class="monitor-position">
       <!--  <div class="top">111</div> -->
        <div class="bottom">
            <div :class="{'left': true, 'shrink-width': !showNormal, 'expand-width': showNormal}" ref="leftContent">
                <div class="shrink-expand" @click="shrinkExpand">
                    <span v-show="showNormal" class="btn-shrink"></span>
                    <span v-show="!showNormal" class="btn-expand"></span>
                </div>
                <div :class="{'content-container': true, 'shrink-opa': !showNormal, 'expand-opa': showNormal}">
                    <div class="content-top clearfix">
                        <el-input v-model="search" :class="{'search-fence': true,'shrink-opa': !showNormal, 'expand-opa': showNormal,'input-w334-h32': true}" clearable placeholder="请输入IMEI" @clear="clearable">
                            <!-- <i slot="suffix" v-show="search" class="el-input__icon icon-close" @click="clearSearch"></i> -->
                            <i slot="suffix" class="el-input__icon icon-search" @click="searchFence"></i>
                        </el-input>
                    </div>
                    <div class="middle-content clearfix">
                        <div class="orgnation">
                            <!-- <popover ref="popover" @nodeClick="nodeClick" special="special"/>  -->
                            <popover v-model="openOrClose" addNewClass="popover-position" @nodeClick="nodeClick" ref="tree" :data="newTreeData"  @hide="openOrClose = false" @show="openOrClose = true">
                                <div slot="treeReference" >
                                    <div class="position-org-name">{{orgName}}
                                        <svg-icon :class="{'svg-icon': true, 'icon-link': true, 'icon-transform': !openOrClose} " icon-class="icon-select" size="16"></svg-icon>
                                    </div>
                                </div>
                            </popover>
                        </div>
                        <el-checkbox v-model="imeiList.includeSub" class="child">包含下级</el-checkbox>
                    </div>
                    <div class="device-contaienr">
                        <el-checkbox-group id="imeiCheckGroup" @scroll.native="handleScroll" class="infinite-list imei-check-group" :infinite-scroll-disabled="isDisabled" infinite-scroll-distance="5" v-infinite-scroll="load" v-model="checkList" @change="checkImei">
                            <div class="device-item" v-for="(item, i) in deviceData" :key="i">
                                <div class="imei-title clearfix">
                                    <!-- <el-checkbox class="imei-check" :label="item.imei + ',' + item.isOnline + ',' + item.deviceModel + ',' + item.createrName">IMEI</el-checkbox> -->
                                    <el-checkbox class="imei-check" :label="item.imei">IMEI</el-checkbox>
                                    <div class="imei-txt">{{ item.imei }}</div>
                                </div>
                                <div class="imei-content">
                                    <el-row>
                                        <el-col :span="12">
                                            <div class="device-left">状态</div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="device-right">
                                                <svg-icon class="svg-icon icon-link" :icon-class="iconStatus[String(item.isOnline)]" size="16"></svg-icon>
                                                <span :class="{'online-txt': item.isOnline == '1', 'offline-txt': item.isOnline == '2'}">{{ item.isOnline == '1' ? '在线' : '离线' }}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="device-left">设备型号</div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="device-right">{{ item.deviceModel }}</div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="device-left">管理用户</div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="device-right">{{ item.createrName }}</div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <div class="map-div">
                <div class="map" id="map"></div>
                <map-type ref="mapType" @selectChangeMap="selectChangeMap" @selectChange="selectChange" ></map-type>
                <map-bottom @zoomOut="zoomOut" @zoomIn="zoomIn" @changeMapType="changeMapType"></map-bottom> 
            </div>
            <div ref="bubble" class="map-bubble">
                <div class="bubble-title">
                    <div class="title">设备信息</div>
                    <span class="btn-close js-close" @click="closeBubble"></span>
                </div>
                <div class="bubble-content">
                    <div class="item clearfix">
                        <div class="item-name">IMEI</div>
                        <div class="item-content">{{bubbleData.imei}}</div>
                    </div>
                    <div class="item clearfix">
                        <div class="item-name">状态</div>
                        <div class="item-content">
                            <svg-icon class="svg-icon icon-link" :icon-class="iconStatus[bubbleData.isOnline]" size="16"></svg-icon>
                            <span :class="{'online-txt': bubbleData.isOnline == '1', 'offline-txt': bubbleData.isOnline == '2'}">{{ bubbleData.isOnline == '1' ? '在线' : '离线' }}</span>
                        </div>
                    </div>
                    <div class="item clearfix">
                        <div class="item-name">电量</div>
                        <div class="item-content">
                            <svg-icon class="svg-icon icon-link" :icon-class="iconPower" size="16"></svg-icon>
                            <span :class="{'ele-power': bubbleData.electric >= 20, 'ele-power-less': bubbleData.electric < 20}">{{bubbleData.electric || '100'}}%</span>  
                        </div>
                    </div>
                    <div class="item clearfix">
                        <div class="item-name">设备型号</div>
                        <div class="item-content">{{bubbleData.deviceModel || '无'}}</div>
                    </div>
                    <div class="item clearfix">
                        <div class="item-name">关联用户</div>
                        <div class="item-content">{{bubbleData.createName || '无'}}</div>
                    </div>
                    <div class="item clearfix">
                        <div class="item-name">定位时间</div>
                        <div class="item-content">{{bubbleData.gpsTime || '无'}}</div>
                    </div>
                    <div class="item clearfix">
                        <div class="item-name">定位地址</div>
                        <div class="item-content">{{bubbleData.address || '无'}}</div>
                    </div>
                </div>
                <div class="bubble-bottom">
                    <el-row>
                        <el-col :span="8">
                            <div class="grid-content">
                                <router-link to="/alarmStatistics"><svg-icon class="svg-icon icon-link" icon-class="icon-alarm" size="24"></svg-icon></router-link>
                                <p class="desc"><router-link to="/alarmStatistics">告警</router-link></p>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content">
                                <router-link to="/monitorTrack"><svg-icon class="svg-icon icon-link" icon-class="icon-track" size="24"></svg-icon></router-link>
                                <p class="desc"><router-link to="/monitorTrack">轨迹</router-link></p>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content">
                                <router-link to="/equipmentManagement"><svg-icon class="svg-icon icon-link" icon-class="icon-instr" size="24"></svg-icon></router-link>
                                <p class="desc"><router-link to="/equipmentManagement">指令</router-link></p>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaiduMap from '../../components/map/baiduMap';
import GoogleMap from '../../components/map/googleMap';
import mapType from '../../components/map/changeMapType';
import mapBottom from '../../components/map/mapBottom';
import { getDeviceList, getDeviceInfo } from '@/api/map/position';
import {listTreeOrg} from '@/api/organization-management/index';
import popover  from '@/components/popover/index';
import GPS from '@/utils/coordinate'
export default {
    components: {
        mapType,
        mapBottom,
        popover
    },
    data() {
        return {
            openOrClose: false,//是否展开组织机构
            orgName: '',//组织机构名称
            newTreeData: [],//组织机构数据
            isDisabled: false,//是否禁用
            showNormal: true,//默认展示
            search: null, //搜索框的值
            map: null, //地图
            center: {//中心点
                lng: 116.3975, 
                lat: 39.9085
            },
            mapOpt: {//地图的基本配置
                zoomControl: true, 
            }, 
            isShow: true,//是否显示地图气泡

            /* bubbleData: [ //气泡内容
                {imei: '2018990081111', isOnline: 1, deviceModel: 'KA-101', createrName: '张三',gpsTime: '2020.09.20 02:04:03', gpsAddress: '四川省成都市武侯区红原县阳阿村大萨达索哈达所多街道2121号' }
            ], */
            bubbleData: {//气泡内容
                imei: '', 
                isOnline: 1,
                deviceModel: '', 
                createName: '',
                gpsTime: '', 
                power: '',
                electric: '20',
                address: ''
            },
            btnData: ['告警', '指令', '轨迹'],//气泡下方按钮
            deviceData: [],
            child: '', //包含下级
            iconStatus: {1: 'icon-online', 2: 'icon-offline'},//在线离线图标
            iconPower: 'icon-power',//电量图标
            isChecked: false,//是否选中
            checkList: [],//选中的imei
            imeiList: {
                includeSub: false,//是否包含下级
                imei: '',//精准查询
                imeiLike: '',//模糊查询
                orgCode: '',//组织机构id
                orgId: '',
                pagesize: 10,//当前页容量
                pageIndex: 1,//当前页
            },
            totalCount: 0,//总共有多少条数据
            allCheckedImei: [],//所有勾选的设备信息
        }
    },
    created() {
        this.listTreeOrg();
    },
    mounted() {
        this.calcHeight();
        this.iconPower = this.bubbleData.electric && this.bubbleData.electric < 20 ? 'icon-power-less' : 'icon-power';
        this.map = new BaiduMap()
        setTimeout(() => {
            this.getDeviceList();
            this.map.initMap('map', this.center, 13);
        }, 500);
    },
    methods: {
        /**
         * 获取机构数据
         * @param {Number} orgId 机构id
         */
        listTreeOrg(orgId){
            return new Promise( ( resolve , reject ) => {
                listTreeOrg({currentOrgId:orgId,excludeChild:true}).then( res => {
                    if( res.code == 200 ) {
                        this.newTreeData = res.data;
                        if (res.data.length > 0) {
                            this.imeiList.orgCode = res.data[0].orgCode;
                            this.imeiList.orgId = res.data[0].orgId
                            this.orgName = res.data[0].orgName;
                        }
                        
                        // resolve('success')
                    }
                })
            }) 
        },
        /** 计算左侧imei列表高度
         * 
         */
        calcHeight() {
            let height = this.$refs.leftContent.clientHeight - 206;
            let dom = document.querySelector('#imeiCheckGroup');
            dom.style.cssText = 'overflow: auto;max-height: '+ height +'px;';
        },
        /** 获取设备信息
         * 
         */
        getDeviceList() {
            let obj = this.imeiList; 
            console.log(obj)
            getDeviceList(obj).then(res => {
                if (res.code == 200) {
                    if (res.data.length > 0) {
                        res.data.forEach(item => {
                            this.deviceData.push(item);
                        });
                    }
                    this.totalCount = res.count;
                }
            });
        },
        /** 左边放大缩小
         * 
         */
        shrinkExpand() {
            this.showNormal = !this.showNormal
        },
        /** 
         * 清空输入框
         */
        clearSearch() {
            this.search = '';
        },
        /** 
         * 搜索围栏
         */
        searchFence() {
            this.deviceData = [];
            if (this.search && this.search.length < 15) {
                this.imeiList.imeiLike = this.search;
            } else {
                this.imeiList.imei = this.search;
            }
            this.getDeviceList();
        },
        /** 地图切换
         * @param {String} val 选择的地图
         */
        selectChangeMap(val) {
            this.map.clearMap('map');
            if (val == 'google') {
                this.map = new GoogleMap();
            } else if (val == 'baidu') {
                this.map = new BaiduMap();
            }
            this.map.initMap('map', this.center, 14);
            if (this.checkList.length > 0) {
                this.showDeviceImei(this.checkList.toString());
            }
            
        },
        /** 切换地图类型
         *@param {String} val 地图类型 
         */
        changeMapType(val) {
            this.map.setMapType(val);
        },
        /** 切换工具箱
         * @param {String} val 工具箱选中的值
         */
        selectChange(val) {
            if (val == 'fullScreen') {
                this.map.fullScreen('map');
            } else if (val == 'ranging') {
                this.rangeDistance();
            }
        },
        /** 
         * 调用测距
         */
        rangeDistance() {
            this.map.computeLength();
        },
        /** 放大地图
         * 
         */
        zoomOut() {
            this.map.zoomOut();
        },
        /** 缩小地图
         * 
         */
        zoomIn() {
            this.map.zoomIn();
        },
        /** 勾选设备
         * @param {Array} arr 勾选的设备信息
         */
        checkImei(arr) {
            if (arr.length <= 0) {
                this.bubbleData = {
                    isOnline: 1,
                    electric: 20,
                };
                this.map.clearOverlays();
                this.map.closeInfoBox();
                this.$refs.bubble.style.display = 'none';
                return;
            }
            this.showDeviceImei(arr);
        },
        /** 
         * 根据勾选设备显示设备信息气泡
         * @param {Array} arr 选中的信息
         */
        showDeviceImei(arr) {
            let obj = {
                imeis: arr.toString()
            } 
            getDeviceInfo(obj).then(res => {
                if (res.code == 200) {
                    var points = [];
                    res.data.forEach((item, index) => {
                        var tempObj = {};
                        if (item) {
                            if (item.gpsInfoVO && item.gpsInfoVO.lng && item.gpsInfoVO.lat) {
                                tempObj = {
                                    address: item.address,
                                    deviceModel: item.deviceModel,
                                    electric: item.hbInfoVo && item.hbInfoVo.internalVoltage || 100,
                                    imei: item.imei,
                                    isOnline: item.isOnline || 2,
                                    gpsTime: item.gpsInfoVO.gpsTime,
                                    lnglat: {
                                        lng: item.gpsInfoVO.lng,
                                        lat: item.gpsInfoVO.lat
                                    }
                                };
                                
                                points.push(this.map.createPoint({lng: item.gpsInfoVO.lng, lat: item.gpsInfoVO.lat}));
                                let opt = {
                                    icon: {
                                        url: require('@/assets/image/map/icon-marker.png'),
                                        size: [40,50]
                                    }
                                };
                                let type = index < res.data.length -1 ? false : true;
                                this.bubbleData = tempObj;
                                this.iconPower = this.bubbleData.electric && this.bubbleData.electric < 20 ? 'icon-power-less' : 'icon-power';
                                this.createMarker(tempObj.lnglat, type, this.$refs.bubble, tempObj);
                                this.allCheckedImei.push(tempObj);
                            } else {
                                this.$message({
                                    message: '该设备无定位信息, 请勿勾选',
                                    type: 'warning'
                                });
                                for(let i = 0; i < this.checkList.length; i++) {
                                    if (item.imei == this.checkList[i]) {
                                        this.checkList.splice(i, 1);
                                    }
                                }
                            }
                        }
                    });
                    this.map.setViewport(points);
                }
            }); 
        },
        /** 创建点和地图气泡
         * @param {Object} point 经纬度
         * @param {Boolean} type 是否默认显示气泡
         * @param {Object} dom 气泡模板
         * @param {Object} daomData 地图气泡数据
         */
        createMarker(point, type, dom, domData) {
            let opt = {
                icon: {
                    url: require('@/assets/image/map/icon-marker.png'),
                    size: [40,50]
                }
            };
           this.map.createMarker(point, opt, dom, type, false, domData, this.clickMarker);
        },
        /** 点击Marker
         * @param {Object} obj 气泡信息
         */
        clickMarker(obj) {
            this.bubbleData = obj;
            this.iconPower = this.bubbleData.electric && this.bubbleData.electric < 20 ? 'icon-power-less' : 'icon-power';
        },
        /** 关闭气泡 */
        closeBubble() {
            this.map.closeInfoBox();
        },
        /** 滚动加载 */
        load () {
        },
        /** 
         * 计算滚动条位置
         */
        handleScroll() {
            let scrollHeight = document.querySelector('#imeiCheckGroup').scrollHeight;
            let height = document.querySelector('#imeiCheckGroup').clientHeight;
            let scrollTop = document.querySelector('#imeiCheckGroup').scrollTop;
            let len = scrollHeight - height - scrollTop;
            if (len == 0) {
                if (this.imeiList.pageIndex < (Math.ceil(this.totalCount / this.imeiList.pagesize))) {
                    this.imeiList.pageIndex++;
                    this.getDeviceList();
                } else {
                    this.imeiList.pageIndex = (Math.ceil(this.totalCount / this.imeiList.pagesize));
                }
            }
        },
        /** 
         * 点击节点
         * @param {Object} obj 选中的机构
         * */
        nodeClick(obj,flag){
            this.imeiList.orgCode = obj.orgCode;
            this.imeiList.orgId = obj.orgId;
            this.orgName = obj.orgName;
            this.getDeviceList();
            if(flag == 'db') {
                this.openOrClose = false
            }
            // this.ruleForm.orgCode = obj.orgId;
            // this.getDeviceList();
        },
        /** 清空 */
        clearable() {
            this.imeiList.imeiLike = '';
            this.imeiList.imei = '';
        }
    }
}
</script>
<style scoped lang="scss">
    .monitor-position {
        position: relative;
        height: 100%;
        .bottom {
            display: flex;
            position: absolute;
            top: 0px;
            right: 20px;
            bottom: 0;
            left: 0;
            width: 100%;
            .left {
                position: relative;
                width: 398px;
                height: 100%;
                box-shadow: 12px 0px 48px 16px rgba(0, 0, 0, 0.03), 9px 0px 28px 0px rgba(0, 0, 0, 0.05), 6px 0px 16px -8px rgba(0, 0, 0, 0.08);
                border-radius: 8px 0px 0px 8px;
                z-index: 4;
                transition: all 1s;
                background-color: #fff;
                &.shrink-width {
                    width: 16px;
                }
                &.expand-width {
                    width: 398px;
                }
                .shrink-expand {
                    position: absolute;
                    top: 30px;
                    right: -16px;
                    span {
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                    }
                    .btn-shrink {
                        background: url(../../assets/image/map/btn-unfold.png);
                        &:hover {
                            background: url(../../assets/image/map/btn-unfold-select.png);
                        }
                    }
                    .btn-expand {
                        background: url(../../assets/image/map/btn-shrink.png);
                        &:hover {
                            background: url(../../assets/image/map/btn-shrink-select.png);
                        }
                    }
                }
                .content-container {
                    transition: opacity 1s ease-in-out;
                    margin: 32px;
                    height: calc(100% - 48px);
                    overflow: auto;
                    &.expand-opa {
                        opacity: 1;
                    }
                    &.shrink-opa {
                        opacity: 0;
                    }
                    .content-top {
                        .search-fence {
                            width: 334px;
                            height: 32px;
                            transition: all 1s ease-in-out;
                            &.expand-opa {
                                width: 334px;
                            }
                            &.shrink-opa {
                                width: 0;
                            }
                        }
                        .btn-add-fence {
                            float: right;
                            width: 80px;
                            height: 32px;
                            padding: 0;
                            line-height: 32px;
                            border-radius: 4px;
                            color: #fff;
                            background-color: #3270FF;
                            transition: all 1s ease-in-out;
                            &.expand-opa {
                                width: 80px;
                            }
                            &.shrink-opa {
                                width: 0;
                            }
                        }
                    }
                    .middle-content {
                        margin-top: 28px;
                        .orgnation {
                            display: inline-block;
                            float: left;
                        }
                        .child {
                            float: right;
                            font-size: 12px;
                            color: #595959;
                        }
                    }
                    .device-contaienr {
                        height: calc(100%-200px);
                        margin-top: 32px;
                        .imei-check-group {
                            overflow: auto;
                            max-height: 300px;
                        }
                        .device-item {
                            margin-bottom: 12px;
                            border-radius: 8px;
                            border: 1px solid #F0F0F0;
                            .imei-title {
                                padding: 13px 24px;
                                border-top-left-radius: 8px;
                                border-top-right-radius: 8px;
                                border-bottom: 1px solid #F0F0F0;
                                background-color: #F7FAFC;
                                .imei-check-container {
                                    display: inline-block;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #262626;
                                    .imei-check {
                                        display: inline-block;
                                        width: 14px;
                                        height: 14px;
                                        margin-right: 12px;
                                        border-radius: 2px;
                                        border: 1px solid #D9D9D9;
                                        vertical-align: middle;
                                        cursor: pointer;
                                        &.checked {
                                            border-color: #3270FF;
                                            background: url(../../assets/image/position/icon-checked.png) no-repeat center center;
                                        }
                                    }
                                }
                                .imei-txt {
                                    float: right;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #595959;
                                }
                            }
                            .imei-content {
                                padding: 9px 24px;
                                background-color: #fff;
                                .device-left {
                                    font-size: 14px;
                                    line-height: 24px;
                                    color: #262626;
                                }
                                .device-right {
                                    font-size: 14px;
                                    text-align: right;
                                    color: #595959;
                                    line-height: 24px;
                                }
                                .online-txt {
                                    margin-left: 4px;
                                    color: #3270FF;
                                }
                                .offline-txt {
                                    margin-left: 2px;
                                    color: #8C8C8C;
                                }
                            }
                        }
                    }
                }
            }
            .map-div {
                flex: 1;
                .map {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .map-bubble {
            display: none;
            width: 306px;
            .bubble-title {
                padding: 19px 24px 5px 24px;
                .title {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 550;
                    color: #262626;
                }
                .btn-close {
                    float: right;
                    display: none;
                    width: 16px;
                    height: 16px;
                    background: url(../../assets/image/map/icon-close.png);
                    cursor: pointer;
                }
            }
            .bubble-content {
                padding: 24px;
                padding-bottom: 16px;
                .m-b6 {
                    margin-bottom: 6px;
                }
                .item {
                    display: flex;
                    width: 258px;
                    margin-bottom: 3px;
                }
                .item-name {
                    display: inline-block;
                    width: 56px;
                    margin-right: 32px;
                    font-size: 14px;
                    text-align: left;
                    line-height: 20px;
                    color: #262626;
                }
                .ver-top {
                    vertical-align: top;
                }
                .item-content {
                    flex: 1;
                    font-size: 14px;
                    line-height: 20px;
                    color: #595959;
                    .online-txt {
                        margin-left: 4px;
                        color: #3270FF;
                    }
                    .offline-txt {
                        margin-left: 2px;
                        color: #8C8C8C;
                    }
                    .ele-power {
                        margin-left: 2px;
                        color: #52C41A;
                    }
                    .ele-power-less {
                        margin-left: 2px;
                        color:#EE3932;
                    }
                }
            }
            .bubble-bottom {
                padding: 10px 0;
                padding-bottom: 0;
                border-top: 1px solid #F0F0F0;
                .grid-content {
                    text-align: center;
                    .desc {
                        a {
                            font-size: 14px;
                            color: #262626;
                        } 
                    }
                }
            }
        }
    }
</style>
<style>
    .search-fence .el-input__inner {
        height: 32px;
    }
    .search-fence .icon-search {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(../../assets/image/fence/icon-search.png);
    }
    .search-fence .icon-close {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(../../assets/image/fence/icon-close.png);
    }
    .search-fence .el-input__suffix {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }
    .search-fence .el-input__suffix-inner .icon-close {
        margin-right: 6px;
    }
    .search-fence .el-input__suffix-inner {
        display: flex;
        align-items: center;
        pointer-events: auto;
        flex-direction: row-reverse;
        cursor: pointer;
    }
    /* 包含下级复选框样式 */
    .child .el-checkbox__label {
        line-height: 20px;
    }
    /* 地图气泡 */
    .monitor-position .infoBox {
        width: 306px;
        box-shadow: 0px 9px 10px 8px rgba(0, 0, 0, 0.02), 0px 6px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px -4px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        background: #fff;
    }
    .monitor-position .infoBox img[src='close.png'] {
        display: none; 
    }
    /* 百度地图中气泡下的小三角 */
    /* .infoBox .bubble-bottom:after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 50%;
        width: 15px;
        height: 15px; 
        box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
        transform: translate(-50%,-50%) rotate(-45deg);
        background: linear-gradient(45deg,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
    } */
    .monitor-position .gm-style .gm-style-iw-c {
        padding: 0;
    }
    .monitor-position .gm-style-iw button[title="关闭"] {
        top: 10px !important;
        right: 18px !important;
    }
    .monitor-position .gm-style-iw button[title="关闭"] img {
        content: url(../../assets/image/map/icon-close.png);
    }
    .monitor-position .gm-style .gm-style-iw-d::-webkit-scrollbar {
        width: 0;
    }
    .monitor-position .gm-style .gm-style-iw-c {  
        box-shadow: 0px 9px 10px 8px rgba(0, 0, 0, 0.02), 0px 6px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px -4px rgba(0, 0, 0, 0.08);
    }
    .infoBox .btn-close {
        display: inline-block !important;
    }
    .infoBox .map-bubble .bubble-bottom {
        padding-bottom: 10px !important;
    }
    .monitor-position .gm-style .gm-style-iw-t::after {
        height: 0;
    }
    .popover-position {
        border: 1px solid #fff !important;
        box-shadow: 0px 9px 10px 8px rgba(0, 0, 0, 0.02), 0px 6px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px -4px rgba(0, 0, 0, 0.08) !important;
    }
    .popover-position .el-tree {
        height: 190px;
    }
    .popover-position .search-top {
        height: 0;
    }
    .position-org-name {
        font-size: 14px;
    }
</style>
