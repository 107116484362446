import request from '@/utils/request';
/** 获取设备列表 */
export function getDeviceList(data) {
    return request({
        url: '/base/device/listPageDevice',
        method: 'get',
        params: data
    });
}
/** 获取设备信息 */
export function  getDeviceInfo(data) {
    return request({
        url: '/statusExt',
        method: 'get',
        params: data
    });
}